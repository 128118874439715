import React from "react"
import { graphql } from "gatsby"
import HomeBanner from "/content/assets/hero/home-banner-2.jpg"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const Index = ({ data }) => {
  return (
    <Layout pagepath={"home"}>
      <Helmet>
            <title>Kentucky Equine Education Project (KEEP) Foundation</title>
      </Helmet>
      <section class="hero hero-banner" id="home-hero">
            <div class="hero-custom">
              <img src={HomeBanner} alt="KEEP" />
            {/* <div class="hero-logo"><span class="hero-action">Horses Give Back<br /> to Kentucky</span><img src={HomeLogo} alt="KEEP"/></div>
            <div class="hero-feature"><img src={HomeFeature} alt="feature" /></div>
            <div class="hero-background"><img src={HomeBackground} alt="background" /></div> */}
            </div>
      </section>  
      <main>
        <h1>Our Mission</h1>
        <p>The mission of the Kentucky Equine Education Project (KEEP) Foundation, a nonprofit 501(c)(3), established in 2014, is to support the economic success of Kentucky’s signature industry by ensuring its continued economic viability, making the industry accessible for all who want to participate, introducing more young people to the industry and its opportunities, and educating the people of the Commonwealth about the importance of horses to the state, its economy, its heritage, and its people.</p>
        <p>The KEEP Foundation seeks to fulfill this mission through:
            <ul>
                <li>Generating wider interest from the general public and creating new fans and participants for Kentucky’s horse industry.</li>
                <li>Raising public awareness on the importance of all horse breeds and disciplines in all 120 counties of the Commonwealth, through educational events and community engagement that explores the breadth and depth of Kentucky’s horse industry.</li>
                <li>Attracting new people and populations to careers in the industry and reducing the barriers of entry to career paths in the industry.</li>
            </ul>
        </p>
      </main>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`